import {React, useEffect, useState} from 'react';
import SlideTitle from "./SlideTitle";
import {SlideQuestion, SlideMessage} from "./SlideQuestion";
import SlideForm from "./SlideForm";
import Loading from "./Loading";
import ReactGA from 'react-ga';

export default function Slide({
                                  url,
                                  suivant,
                                  precedent,
                                  datas,
                                  setDatas,
                                  setcategorieActive,
                                  setSuivantSlide,
                                  loading,
                                  setloading,
                                  historique,
                                  setSlideEncours,
                              }) {

    const [slide, setSlide] = useState(null);

    useEffect(() => {
        setloading(true);
        // if (slide && slide.historique){
        //     historique.push(slide)
        // }
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    result = result.content
                    window.history.pushState(null, "", window.location.href);
                    setSlide(result)
                    setSlideEncours(result)
                    if (result && result.historique) {
                        historique.push(result)
                    }
                    if (result.evenement){
                        ReactGA.initialize(result.gga);
                        ReactGA.event({
                            category: 'tunnel',
                            action: result.evenement,
                        });
                    }
                    setSuivantSlide(result.suivant)
                    // creation des datas à envoyer au moment de la validation
                    datas.slide = result.name;
                    setDatas({reponses: {}, question: result.id})
                    // positionnement de la catégorie
                    setcategorieActive(result.categorie)
                    setloading(false);
                },
                (error) => {
                    alert("Nous sommes désolé cette question n'existe pas. Si le problème persiste merci de contacter le support");
                    setloading(false);
                }
            )
    }, [url])  // eslint-disable-line

    if (loading) {
        return <Loading/>
    } else {
        if (slide) {
            return (
                <div className="container">
                    <SlideTitle slide={slide}/>
                    <SlideQuestion question={slide.question}/>
                    <SlideMessage message={slide.message}/>
                    <SlideForm slide={slide} datas={datas} setDatas={setDatas} suivant={suivant} precedent={precedent}
                               setSuivantSlide={setSuivantSlide}/>
                </div>)
        }
    }


}