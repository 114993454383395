import {React, useEffect, useState} from 'react';
import Slide from './Component/Slide'
import Header from './Component/html/Header.js'
import Timeline from './Component/html/Timeline.js'
import {useCookies} from 'react-cookie';
import Loading from "./Component/Loading";
import ReactGA from 'react-ga';


function App() {

    const endpoint = process.env.REACT_APP_ENDPOINT
    const token = window.location.hash ? (window.location.hash.replace('#', '')) : null;

    const [currentSlide, setCurrentSlide] = useState(false);
    const [slideEnCours, setSlideEncours] = useState(false);
    const [historique] = useState([]);
    const [suivantSlide, setSuivantSlide] = useState(null);
    const [categorieActive, setcategorieActive] = useState(null);
    const [datas, setDatas] = useState({reponses: {}});
    const [loading, setloading] = useState(true);
    const [cookies, setCookie] = useCookies(null);

    useEffect(function () {

        let uniqueSession = "";
        if (cookies.session === undefined) {
            let uuid = Date.now() + Math.floor(Math.random() * 10000);
            setCookie('session', uuid)
            uniqueSession = uuid
        } else {
            uniqueSession = cookies.session
        }

        let clef = window.location.pathname.replaceAll('/', '');
        if (token == null) {
            //console.log(endpoint + "/public/questionnaires/" + process.env.REACT_APP_QUESTIONNAIRE + (clef ? "/" + clef : '') + '/?s=' + uniqueSession)
            fetch(endpoint + "/public/questionnaires/" + process.env.REACT_APP_QUESTIONNAIRE + (clef ? "/" + clef : '') + '/?s=' + uniqueSession)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.content === undefined) {
                            if (window.confirm("Lancer le questionnaire")) {
                                setTimeout(() => {
                                    window.location.reload();
                                }, "3000")
                            }
                        }
                        setCurrentSlide(result.content.url)
                        if (result.content.gga){
                            ReactGA.initialize(result.content.gga);
                            ReactGA.pageview(window.location.pathname + window.location.search);
                        }
                    }
                    // ,
                    // () => {
                    //     if (window.confirm("Nous sommes désolé ce questionnaire n'existe pas. Si le problème persiste merci de contacter le support. Souhaitez-vous recommencer ? 000#02")) {
                    //         setTimeout(() => {
                    //             window.location.reload();
                    //         }, "3000")
                    //     }
                    //     setloading(false);
                    // }
                )
        } else {
            //console.log('#2', endpoint + token)
            setCurrentSlide(endpoint + token)
        }
    }, []) // eslint-disable-line

    function precedent() {
        // console.log(historique)
        //  console.log('slide precedante')
        if (historique.length > 0) {
            if (slideEnCours.historique){
                historique.pop();
            }
            var prochaineSlide = historique[historique.length -1];
            if (prochaineSlide) {
                //console.log('#3', prochaineSlide)
                setCurrentSlide(prochaineSlide.url)
            }
        }
    }

    window.onpopstate = function () {
        // console.log('slide precedante')
        precedent()
    }

    function suivant(slide, url = null) {
        var prochaineSlide = url ? url : suivantSlide
        if (!slide.terminer && prochaineSlide) {
            fetchData(prochaineSlide)
        } else {
            alert("C'est terminé !")
        }
    }

    async function fetchData(prochaineSlide) {
        var formData = new FormData();
        formData.append('r', JSON.stringify(datas))
        fetch(endpoint + "/public/questionnaires/reponse/" + cookies.session + "/", {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then((res) => {
                return res.content
            }).then(() => setCurrentSlide(prochaineSlide))
    }

    //console.log(currentSlide)

    if (currentSlide) {
        return (
            <div className="main-app-container">
                <Header/>

                <Slide url={currentSlide}
                       suivant={suivant}
                       precedent={precedent}
                       datas={datas}
                       setDatas={setDatas}
                       setcategorieActive={setcategorieActive}
                       suivantSlide={suivantSlide}
                       setSuivantSlide={setSuivantSlide}
                       loading={loading}
                       setloading={setloading}
                       historique={historique}
                       setSlideEncours={setSlideEncours}
                ></Slide>

                {/*
                ------------------------------------------
                        BLOC RESULTAT LAST SLIDE
                ------------------------------------------

                <div className='box-resultats'>
                    Le résultat de votre simulation est de : <strong>12225 €</strong>
                </div>

                */}
                {slideEnCours &&
                    <Timeline historique={historique} precedent={precedent} categorieActive={categorieActive}
                              questionnaire={slideEnCours.questionnaire}/>}
            </div>
        );
    } else {
        return (<Loading/>)
    }
}

export default App;
