import {React, useEffect, useState} from 'react';

export default function Timeline({historique, precedent, categorieActive, questionnaire}) {

    const [categories, setCategories] = useState([]);


    useEffect(() => {

        fetch(process.env.REACT_APP_ENDPOINT + '/public/categories/'  + questionnaire + '/')
            .then(res => res.json())
            .then(
                (result) => {
                    setCategories(result.content)
                },
                (error) => {
                    alert(error)
                }
            )
    }, [questionnaire])

    function Precedent() {
        return (
            <span className="timeline-arrow" onClick={precedent}>
                <i className="fas fa-arrow-circle-left"></i>
            </span>
        )
    }

    return (
        <div className="timeline">
            <div className="container-lg">
                <div className="d-flex justify-content-between">
                    {historique.length > 1 && <Precedent/>}
                    {
                        categories.map(function (categorie, i) {
                            return (
                                <span className={'timeline-link ' + (categorieActive === categorie.id ? 'timeline-active' : '')} key={i}>
                                    <span>{categorie.numero}</span><br/>
                                    <small className="d-none d-md-block">{categorie.titre}</small>
                                </span>
                            )
                        })
                    }
                </div>
                
            </div>
            <div className="container-fluid">
                <div className='timeline-links'>
                    <a href="https://www.vendre-ma-maison.re/mentionslegales.html" title=''>Mentions légales</a>
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <a href="https://www.vendre-ma-maison.re/mentionslegales.html" title=''>Conditions générales d’Utilisation</a>
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <a href="https://www.vendre-ma-maison.re/mentionslegales.html" title=''>Politique de confidentialité - RGPD</a>
                </div>
            </div>

        </div>
    )
}