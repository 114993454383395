import {React} from 'react';

export default function IconeTitre({champ}) {

    const prefixIcone = 'icon';
    const suffix = champ.icone;
    const iconeBtn = (prefixIcone + ' ' + suffix);

    return (
        <>
            <div className="d-flex justify-content-center mt-2">
                <div className="d-flex justify-content-center align-items-center main-app-icon">
                    <i className={iconeBtn}></i>
                </div>
            </div>
        </>
    )
}


