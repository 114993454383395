import {React, useEffect, useState} from 'react';

export default function Checkbox({champ, datas}) {

    const [value, setValue] = useState(false);

    const prefix = 'omi-';
    const suffix = champ.id;
    const idFor = (prefix + suffix);

    useEffect(() => {
        //setValue(champ.valeurResultat===champ.libelle)
        // todo à terminer
        setValue(false);
    }, [])

    function change(e) {

        setValue(!value)

        const t = e.target
        if (t.checked){
            datas.reponses[champ.id] =
                {
                    id:champ.id,
                    type:champ.type,
                    libelle:champ.libelle,
                    valeur:champ.libelle,
                    clef:champ.clef
                }
        }else{
            delete datas[champ.id];
        }
    }

    return (
        <>
        <div class="form-check">
            <input type="checkbox" id={idFor} name={champ.id} className="form-check-input" onClick={change} required={champ.obligatoire} checked={value} />
            <label className="form-check-label" htmlFor={idFor}>{champ.libelle}</label>
        </div>
        </>
    )
}