import {React} from 'react';

export default function Btn({champ, slide, datas, suivant}) {

    function clic(e) {

        if (champ.redirection==='url' && champ.lien){
            document.location.href=champ.lien;
        }else{
            datas.reponses[champ.id] = {
                id:champ.id,
                type:champ.type,
                libelle:champ.libelle,
                valeur:champ.libelle,
                clef:champ.clef
            }

            suivant(slide,champ.url);
        }

    }

    const prefixIcone = 'fa-3x fal';
    const suffix = champ.icone;
    const iconeBtn = (prefixIcone+' '+suffix);

    // champ.img = "/images/icone-type-appartement.png"

    if (champ.naturebtn==="image") {
        return (
            <button className="btn btn-lg btn-myline w-100 px-4 py-3 mb-2" type="button" onClick={clic}>
                <img className="img-btn mb-2" src={champ.img} alt={champ.libelle} height="100" /><br/>
                <span className="d-inline-block">{champ.libelle}</span>
            </button>
        )
    } else if (champ.naturebtn==="icone") {
        return (
            <button className="btn btn-lg btn-myline w-100 px-4 py-3 mb-2" type="button" onClick={clic}>
                <i className={iconeBtn}></i><br/>
                <span className="d-inline-block">{champ.libelle}</span>
            </button>
        )
    } else {
        return (
            <button className="btn btn-lg btn-myline w-100 px-4 py-3 mb-2" type="button" onClick={clic}>
                <span className="d-inline-block">{champ.libelle}</span>
            </button>
        )
    }


    
    

}