import {React} from 'react';
import IconeTitre from './typeAction/IconeTitre'

export default function Titre ({slide}) {

    return (
        <>
        <h1 className="main-app-title px-sm-5 h1 text-primary mb-3">
            {slide.name}
        </h1>
            {slide.icone && slide.afficherIcone && slide.afficherIcone==="icone" && <IconeTitre champ={{icone:slide.icone}}></IconeTitre>}
        </>
    )
}