import {React} from 'react';

export default function header () {
    return (
        <header className="header">
            <div className="container">
                <img className="logo" src="/images/logo-vmm.svg" alt="vendre-ma-maison.re" />
            </div>
        </header>
    )
}