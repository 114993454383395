import {React} from 'react';
import Input from './typeAction/Input'
import Btn from './typeAction/Btn'
import Checkbox from './typeAction/Checkbox'
import Code from './typeAction/Code'
import Autocomplete from './typeAction/Autocomplete'
import Contenu from './typeAction/Contenu'
import Chargement from './typeAction/Chargement'

export default function Formulaire({slide, suivant, datas, setSuivantSlide}) {

    function action(champ) {
        if (champ.type === "button") {
            return <Btn champ={champ} datas={datas} suivant={suivant} slide={slide} setSuivantSlide={setSuivantSlide}/>
        } else if (champ.type === "text" || champ.type === "email" || champ.type === "number") {
            return <Input champ={champ} datas={datas}/>
        } else if (champ.type === "checkbox") {
            return <Checkbox champ={champ} datas={datas}/>
        } else if (champ.type === "autocomplete") {
            return <Autocomplete champ={champ} datas={datas}/>
        } else if (champ.type === "code") {
            return <Code champ={champ} datas={datas} setSuivantSlide={setSuivantSlide} slide={slide}/>
        } else if (champ.type === "contenu") {
            return <Contenu champ={champ} datas={datas}/>
        } else if (champ.type === "chargement") {
            return <Chargement champ={champ} suivant={suivant} slide={slide} />
        }
    }

    var afficherSubmitButton = false;

    function submit(e) {
        e.preventDefault();
        suivant(slide)
    }

    function SubmitButton() {
        if (!slide.terminer && slide.suivant) {
            return (
                <div className="text-center pt-3">
                    <button className="btn btn-primary btn-lg px-4" type="submit">
                        {slide.suivantText ? slide.suivantText : 'Suivant'}
                        <i className="fas fa-arrow-circle-right ms-2"></i>
                    </button>
                </div>
            )
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="d-flex flex-column flex-wrap flex-md-row align-items-center justify-content-center gap-3">
                {slide.champs.map(function (champ, i) {
                    if (champ.type === "text" || champ.type === "email" || champ.type === "number" || champ.type === "checkbox" || champ.type === "autocomplete" || champ.type === "code" || champ.type === "contenu") {
                        afficherSubmitButton = true
                    }
                    return (
                        <div className="form-floating flex-md-fill mb-2" key={i}>
                            {action(champ, i)}
                        </div>
                    )
                })}
            </div>
            {afficherSubmitButton && <SubmitButton/>}
        </form>
    )
}