import {React, useState} from 'react';
import AsyncSelect from 'react-select/async';

export default function Autocomplete({champ, datas}) {

    const [value, setValue] = useState([]);

    const loadOptions = async (newValue) => {
        return fetchDatas(newValue)
    };

    const fetchDatas = async (newValue) => {
        var formData = new FormData();
        formData.append('q', newValue)
        return await fetch(process.env.REACT_APP_ENDPOINT+`/public/questionnaires/`+champ.id +`/autocomplete`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then((res) => {
                return res.content
            })
    };

    function onchange(e) {
        setValue(e.value)
        datas.reponses[champ.id] =
            {
                id:champ.id,
                type:champ.type,
                libelle:champ.libelle,
                valeur:e.value,
                clef:champ.clef
            }
    }

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                autoFocus={true}
                defaultOptions
                placeholder={champ.libelle}
                loadingMessage={(a) => "Recherche en cours " + a.inputValue}
                noOptionsMessage={() => "Aucune proposition trouvée"}
                onChange={onchange}
                required
            />
            <input type="text" value={value} id={champ.id} required style={{display:'none'}} onChange={()=>console.log('*')}/>
        </>);
}
