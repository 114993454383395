import {React, useState} from 'react';

export default function Code({champ, datas, setSuivantSlide, slide}) {

    const [value, setValue] = useState([]);

    function change(e) {
        const t = e.target;
        setValue(t.value)
        setSuivantSlide(slide.suivant + "?code="+t.value);
    }

    const prefix = 'omi-';
    const suffix = champ.nom;
    const idFor = (prefix + suffix);

    return (
        <>
            <input className="form-control" id={idFor} placeholder={champ.libelle} type={champ.type} value={value} onChange={change} name={champ.nom} required/>
            <label htmlFor={idFor}>{champ.libelle}</label>
        </>
    )
}
