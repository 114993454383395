import {React, useState} from 'react';

export default function Chargement({champ, suivant,slide}) {

    const [value, setValue] = useState(0);

    if (value <= 100) {
        setTimeout(function () {
            setValue(value + 100/champ.temps)
        }, 1000);
    }else{
        setValue(100)
        suivant(slide)
    }

    return (
        <>
            <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                     style={{'width': value + '%'}}
                     aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">{Math.round(value)}%
                </div>
            </div>
        </>
    )
}
