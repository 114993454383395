import {React, useEffect, useState} from 'react';

export default function Input({champ, datas}) {

    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(champ.valeurResultat)
    }, []) // eslint-disable-line

    function change(e) {
        const t = e.target;
        setValue(t.value)
        datas.reponses[champ.id] =
            {
                id:champ.id,
                type:champ.type,
                libelle:champ.libelle,
                valeur:t.value,
                clef:champ.clef
            }
    }

    const prefix = 'omi-';
    const suffix = champ.nom;
    const idFor = (prefix + suffix);

    return (
        <>
            <input className="form-control" id={idFor} placeholder={champ.libelle} type={champ.typeText} value={value} onChange={change} required={champ.obligatoire} />
            <label htmlFor={idFor}>{champ.libelle}</label>
        </>
    )
}
